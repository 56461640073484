import httpClient from '../httpClient/httpClient';

const ClinicEndpointsService = {
  getClinicEndpointsByClinicID(clinicID, startDate, endDate) {
    return httpClient.get('api/get-clinic-endpoints-by-clinic-id', {
      params: {
        clinicID,
        startDate,
        endDate,
      },
    });
  },
};

export default ClinicEndpointsService;
