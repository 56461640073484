<template>
  <v-container v-if="hasPermission" fluid>
    <v-row justify="center">
      <v-col cols="12">
        <mex-heading content="Clinic Endpoints View" />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col align-self="center" cols="5">
        <v-autocomplete
          v-model="selectedOrganization"
          :items="organizationNames"
          color="primaryAccent"
          dense
          hide-details
          label="Organization"
          outlined
        >
          <template v-slot:item="data">
            <v-list-item-content>
              <v-list-item-title>{{ data.item.text }}</v-list-item-title>
              <v-list-item-subtitle class="ma-2">{{ data.item.description }}</v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col align-self="center" cols="5">
        <v-autocomplete
          v-model="selectedClinic"
          :filled="!selectedOrganization"
          :items="clinicNames"
          :readonly="!selectedOrganization"
          color="primaryAccent"
          dense
          hide-details
          label="Clinic"
          outlined
        >
          <template v-slot:item="data">
            <v-list-item-content>
              <v-list-item-title>{{ data.item.text }}</v-list-item-title>
              <v-list-item-subtitle class="ma-2">{{ data.item.description }}</v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col align-self="center" class="pr-0" cols="2">
        <v-menu
          ref="startDateMenu"
          v-model="startDateMenu"
          :close-on-content-click="false"
          :return-value.sync="startDate"
          min-width="auto"
          offset-y
          transition="scale-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="startDate" v-bind="attrs" v-on="on" dense hide-details label="Start date" outlined readonly></v-text-field>
          </template>
          <v-date-picker v-model="startDate" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn @click="startDateMenu = false"> Cancel</v-btn>
            <v-btn @click="$refs.startDateMenu.save(startDate) || $store.commit('selectedProperties/setClinicEndpointsViewStartDate', startDate)"> START DATE</v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col align-self="center" class="pl-0" cols="auto">
        <mex-btn icon="mdi-close" iconOnly small @click="startDate = null || $store.commit('selectedProperties/setClinicEndpointsViewStartDate', null)" />
      </v-col>
      <v-col align-self="center" class="pr-0" cols="2">
        <v-menu
          ref="endDateMenu"
          v-model="endDateMenu"
          :close-on-content-click="false"
          :return-value.sync="endDate"
          min-width="auto"
          offset-y
          transition="scale-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="endDate" v-bind="attrs" v-on="on" dense hide-details label="End date" outlined readonly></v-text-field>
          </template>
          <v-date-picker v-model="endDate" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn @click="endDateMenu = false"> Cancel</v-btn>
            <v-btn @click="$refs.endDateMenu.save(endDate) || $store.commit('selectedProperties/setClinicEndpointsViewEndDate', endDate)"> END DATE</v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col align-self="center" class="pl-0" cols="auto">
        <mex-btn icon="mdi-close" iconOnly small @click="endDate = null|| $store.commit('selectedProperties/setClinicEndpointsViewEndDate', null)" />
      </v-col>
      <v-col align-self="center" cols="auto">
        <mex-btn :disabled="!selectedClinic" icon="mdi-refresh" iconOnly @click="fetchClinicEndpoints" />
      </v-col>
    </v-row>
    <mex-sperm-spinner v-if="clinicEndpointsViewLoading" :spinnerText="clinicEndpointsViewLoadingText" />
    <template v-else>
      <v-timeline class="mt-10" dense>
        <v-timeline-item v-for="(clinicEndpoint, idx) in clinicEndpoints" :key="idx">
          <expansion-sheet color="foreground" rounded>
            <template v-slot:header>
              <v-row>
                <v-col align-self="center" cols="auto">
                  <mex-p :content="$dateFormatter.convertJsonDate(clinicEndpoint[0].retrievalDate).date" class="mb-0" fontSize="h6" />
                </v-col>
                <v-col cols="auto">
                  <v-divider vertical />
                </v-col>
                <v-col align-self="center" cols="auto">
                  <mex-p :content="clinicEndpoint.length + ' features reported'" class="mb-0" />
                </v-col>
              </v-row>
            </template>
            <template v-slot:content>
              <mex-data-table
                :data="clinicEndpoint"
                :headers="clinicEndpointsTableHeaders"
                tableClass="foreground"
                :sortBy="['Feature.keyfield']"
                :footer-props="{
                  'items-per-page-options': getTablePagination.rowsPerPage,
                }"
                :items-per-page="getTablePagination.defaultRowsPerPage"
              >
                <template v-slot:item.activationDate="{ item }">
                  {{ $dateFormatter.convertJsonDate(item.activationDate).full }}
                </template>
                <template v-slot:item.deactivationDate="{ item }">
                  {{ $dateFormatter.convertJsonDate(item.deactivationDate).full }}
                </template>
              </mex-data-table>
            </template>
          </expansion-sheet>
        </v-timeline-item>
        <v-timeline-item v-if="selectedOrganization && selectedClinic && selectedLocation && Object.keys(clinicEndpoints).length === 0">
          <mex-p class="mb-0" content="No features have been reported for this location" fontSize="h6" />
        </v-timeline-item>
      </v-timeline>
    </template>
    <save-request :showSaveRequest="showSaveRequest" @closeSaveRequest="showSaveRequest = false" />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import SaveRequest from '../../components/LicSrvComponents/SaveRequest.vue';
import ClinicsService from '../../services/clinics.service';
import OrganizationsService from '../../services/organizations.service';
import ExpansionSheet from '../../components/LicSrvComponents/ExpansionSheet.vue';
import ClinicEndpointsService from '../../services/clinicEndpoints.service';
import requiredPermissions from '../../requiredPermissions';
import tablePagination from '../../config/tablePagination.config';
import {assignSeveralProperties} from '../../functions/assignPropertyIfExists'

export default {
  name: 'ClinicEndpointsView',
  components: { SaveRequest, ExpansionSheet },
  computed: {
    ...mapGetters('sysAuth', ['getUserPermissions']),
    getSelectedClinic() {
      return this.selectedClinic;
    },
    getTablePagination() {
      return tablePagination;
    }
  },
  data() {
    return {
      // visualization
      editMode: false,
      showSaveRequest: false,
      clinicEndpointsViewLoading: false,
      clinicEndpointsViewLoadingText: 'loading clinic endpoints',
      // selection data
      selectedOrganization: null,
      selectedClinic: null,
      selectedLocation: null,
      organizationNames: [],
      clinicNames: [],
      preSelection: {
        clinic: null,
        organization: null,
      },
      startDate: null,
      startDateMenu: false,
      endDate: this.$dateFormatter.convertJsonDate(new Date().toISOString()).date,
      endDateMenu: false,
      // data
      clinicEndpoints: [],
      clinicEndpointsTableHeaders: [
        {
          text: 'Endpoint name',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Endpoint type',
          align: 'start',
          sortable: true,
          value: 'endpointType',
        },
        {
          text: 'Template ID',
          align: 'center',
          sortable: true,
          value: 'parentTemplateID',
        },
        {
          text: 'Active',
          align: 'center',
          sortable: true,
          value: 'isJobActive',
        },
        {
          text: 'Config enabled',
          align: 'center',
          sortable: true,
          value: 'isConfigEnabled',
        },
        {
          text: 'base URI',
          align: 'center',
          sortable: false,
          value: 'baseUri',
        },
        {
          text: 'URI',
          align: 'center',
          sortable: false,
          value: 'uri',
        },
        {
          text: 'Port',
          align: 'center',
          sortable: false,
          value: 'port',
        },
        {
          text: 'Retry-Interval',
          align: 'center',
          sortable: false,
          value: 'retryInterval',
        },
        {
          text: 'RD lookup type',
          align: 'center',
          sortable: false,
          value: 'useRdLookupType',
        },
        {
          text: 'Call Count',
          align: 'center',
          sortable: false,
          value: 'callCount',
        },
      ],
      hasPermission: false,
    };
  },
  watch: {
    selectedOrganization: {
      handler() {
        this.$store.commit('selectedProperties/setClinicEndpointsViewOrganization', this.selectedOrganization);
        this.fetchClinicNames();
        this.clearView();
      },
    },
    selectedClinic: {
      handler() {
        this.$store.commit('selectedProperties/setClinicEndpointsViewClinic', this.selectedClinic);
        this.fetchClinicEndpoints();
        this.clearView();
      },
    },
  },
  created() {
    this.$userPermissions.fetchCurrentUserPermissions(requiredPermissions.clinicAdministration, this.$store)
      .then((hasPermission) => {
        if (hasPermission) {
          this.hasPermission = true;
          this.fetchOrganizationNames();
          assignSeveralProperties([this.$route.params, this.$route.query], ['organization', 'clinic'], this.preSelection, this.$store, 'clinicEndpointsView');
          if (this.$store.getters["selectedProperties/clinicEndpointsViewStartDate"]) {
            this.startDate = JSON.parse(this.$store.getters["selectedProperties/clinicEndpointsViewStartDate"]);
          }
          if (this.$store.getters["selectedProperties/clinicEndpointsViewEndDate"]) {
            this.endDate = JSON.parse(this.$store.getters["selectedProperties/clinicEndpointsViewEndDate"]);
          }
        } else {
          this.$router.push({ name: "NotFound" });
        }
      })
      .catch(() => {
        this.$router.push({ name: "NotFound" });
      })
  },
  beforeRouteLeave(to, from, next) {
    if (this.editMode && !this.showSaveRequest) {
      this.showSaveRequest = true;
    } else {
      next();
    }
  },
  methods: {
    clearView() {
      this.clinicEndpoints = [];
    },
    switchEditMode(value) {
      this.editMode = value;
    },
    fetchOrganizationNames() {
      if (this.organizationNames.length === 0) {
        OrganizationsService.getOrganizationNames()
          .then((organizationResponse) => {
            organizationResponse.data.forEach((orga) => {
              this.organizationNames.push({
                value: orga.OrganizationID,
                text: orga.name,
              });
            });
            if (this.preSelection.organization) {
              this.selectedOrganization = this.preSelection.organization;
              this.preSelection.organization = null;
            } else {
              this.selectedOrganization = null;
            }
          })
          .catch((err) => {
            this.$toast.error(err);
          });
      }
    },
    fetchClinicNames() {
      this.clinicNames = [];
      if (this.selectedOrganization) {
        ClinicsService.getClinicNames(this.selectedOrganization)
          .then((clinicResponse) => {
            clinicResponse.data.forEach((clinic) => {
              this.clinicNames.push({
                value: clinic.ClinicID,
                text: clinic.name,
              });
            });
            if (this.preSelection.clinic) {
              this.selectedClinic = this.preSelection.clinic;
              this.preSelection.clinic = null;
            } else {
              this.selectedClinic = null;
            }
          })
          .catch((err) => {
            this.$toast.error(err);
          });
      }
    },
    fetchClinicEndpoints() {
      this.clinicEndpointsViewLoading = true;
      ClinicEndpointsService.getClinicEndpointsByClinicID(this.selectedClinic, this.startDate, this.endDate).then((response) => {
        this.clinicEndpoints = response.data;
        this.clinicEndpointsViewLoading = false;
      });
    },
  },
};
</script>

<style></style>
